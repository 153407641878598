import { envConfig } from "./env.config";

const { origin } = window.location;

export const isCNHost = (() => {
  // Default development environment for China region
  if (process.env.NODE_ENV === "development") {
    return true;
  }
  return Object.values(envConfig.CN).includes(origin);
})();
